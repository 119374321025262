const CustomerService = require("@/services/CustomerService");

export const namespaced = true;

export const state = {
    firstName: null,
    lastName: null,
    email: null,
    token: null,
    complexId: null,
    cardNumber: null
};

export const mutations = {
    SET_CUSTOMER(state, {firstName, lastName, email}){
        state.firstName = firstName
        state.lastName = lastName
        state.email = email
    },
    REGISTER_ANONYM(state, {data}){
        if(data.firstName)
            state.firstName = data.firstName

        if(data.lastName)
            state.lastName = data.lastName

        if(data.email)
            state.email = data.email

        if(data.anonymToken)
            state.token = data.anonymToken

        if(data.complexId)
            state.complexId = data.complexId
    },
    SET_CARD_NUMBER(state, cardNumber){
        state.cardNumber = cardNumber
    }
};

export const actions = {
    setCustomer ({commit}, {firstName, lastName, email}) {
        commit('SET_CUSTOMER', {firstName, lastName, email})
    },
    async registerAnonym({commit, state}){
        let {status, data} = await CustomerService.registerCustomerAnonym(
            state.firstName,
            state.lastName,
            state.email
        )
        if(status === 200 && !data.error){
            commit('REGISTER_ANONYM', {data})
        }
    },
    setCardNumber({commit},cardNumber){
        commit('SET_CARD_NUMBER', cardNumber)
    }
};
