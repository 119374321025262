<template>
    <v-overlay class="loader" :value="loaderToShow" z-index="100" color="black">
        <spring-spinner
            class="spinner"
            :animation-duration="3000"
            :size="50"
            :color="loaderColor"
        />

        <span>{{loaderMessage}}</span>
    </v-overlay>

</template>

<script>
    import { SpringSpinner } from 'epic-spinners';
    export default {
        name: "Loader",
        components: {
            SpringSpinner
        },
    }

    /* Utilisation du composant :
        this.showLoader({
            message: "",
            color: ""
        })
     */
</script>

<style scoped>
.loader >>> .v-overlay__content{
    width: 350px;
    min-height: 120px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    color: black;
    border-radius: 4px;
}

.spinner {
    margin-right: 20px;
}
</style>
