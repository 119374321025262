import {isNotNull, isNull, config} from '@/assets/js/Utils.js';
const BasketService = require("@/services/BasketService");

export const namespaced = true;

export const state = {
    id: -1,
    products: [],
    totalAmount: 0.0,
    subState: null,
    complexId: null,
    orderNumber: null,
    orderOrigin: config.orderOrigin,
    isSuspect: null,
    lastTicket: null,
    ticketYomani: null,
    orderToCheck: null,
    orders: [],
    coupon: null, 
    comment: null
};

export const mutations = {
    ADD_PRODUCT (state, {product, qty}) {
        let productTemp = state.products.find(productTemp => productTemp.product.id === product.id)
        if(productTemp){
            productTemp.qty += qty
        }else{
            state.products.push({
                id: product.id,
                product: product,
                qty: qty
            })
        }
        state.totalAmount += product.price * qty
    },
    DECREASE_PRODUCT (state, {product, qty}) {
        let productTemp = state.products.find(productTemp => productTemp.product.id === product.id)
        if(productTemp){
            if(productTemp.qty > qty){
                productTemp.qty -= qty
                state.totalAmount -= product.price * qty
            }else{
                let productIndex = state.products.findIndex(productTemp => productTemp.product.id === product.id)
                state.totalAmount -= productTemp.product.price * productTemp.qty
                state.products.splice(productIndex, 1)
            }
        }
    },
    REMOVE_PRODUCT (state, {product}) {
        let productTemp = state.products.find(productTemp => productTemp.product.id === product.id)
        let productIndex = state.products.findIndex(productTemp => productTemp.product.id === product.id)
        if(productIndex > -1){
            state.totalAmount -= productTemp.product.price * productTemp.qty
            state.products.splice(productIndex, 1)
        }
    },
    CLEAR_BASKET (state) {
        state.products = []
        state.totalAmount = 0.0
        state.subState = null
        state.complexId = null
        state.orderNumber = null
        state.orderOrigin = config.orderOrigin
        state.isSuspect = null
    },
    UPDATE_BASKET (state, {basketInDatabase}) {
        state.id = basketInDatabase.id
        state.products = basketInDatabase.basketProducts
        state.totalAmount = basketInDatabase.totalAmount
        state.complexId = basketInDatabase.complexId
        state.orderNumber = basketInDatabase.orderNumber
    },
    UPDATE_LAST_TICKET (state, ticket){
        state.lastTicket = ticket
    },
    UPDATE_ORDER_TO_CHECK (state, order){
        state.orderToCheck = order
    },
    UPDATE_YOMANI_TICKET (state, ticket){
        state.ticketYomani = ticket
    },
    UPDATE_ORDERS (state, orders){
        state.orders = orders
    },
    UPDATE_COUPON (state, coupon){
        state.coupon = coupon
    },
    UPDATE_COMMENT (state, comment){
        state.comment = comment
    },
    UPDATE_TICKET_COMMENT (state, comment){
        if(state.lastTicket){
            state.lastTicket.comment = comment
        }
    },
    SET_PRODUCT_IS_DELETED (state, product){
        let productTemp = state.products.find(productTemp => productTemp.product.id === product.id)
        if(productTemp){
            productTemp.isDeleted = true
        }
    }
};

export const actions = {
    addProductInBasket ({commit}, {product, qty}) {
        commit('ADD_PRODUCT', {product, qty})
    },
    decreaseProductInBasket ({commit}, {product, qty}) {
        commit('DECREASE_PRODUCT', {product, qty})
    },
    removeProductInBasket ({commit}, {product}) {
        commit('REMOVE_PRODUCT', {product})
    },
    clearBasket ({commit}) {
        commit('CLEAR_BASKET')
    },
    updateBasketInDatabase({commit, getters, rootState}, basketComplexId = null){
        return new Promise(async (resolve, reject) => {
            let {status, data} = await BasketService.manageBasketProducts(getters.basketProductsFormatted, basketComplexId, rootState.customer.token)
            if(status === 200 && !data.error){
                let response;
                if(basketComplexId){
                    response = await BasketService.getBasketByComplexId(basketComplexId)
                }else{
                    response = await BasketService.getBasketsInProgress(rootState.customer.token)
                }
                if(response.status === 200 && isNotNull(response.data)){
                    let basket = null
                    if(basketComplexId){
                        basket = response.data
                    }else{
                        basket = response.data[0]
                    }
                    commit("UPDATE_BASKET", {basketInDatabase: basket})
                    commit("UPDATE_COMMENT", basket.comment)
                    resolve()
                }else{
                    reject()
                }
            }
        })
    },
    validBasketInDatabase({rootState, state, commit}, {comment = ""}){
        // await dispatch('updateBasketInDatabase')
        return new Promise(async (resolve, reject) => {
            let {status, data} = await BasketService.validBasket(rootState.customer.token, comment, state.isSuspect, state.orderOrigin)
            if(status === 200 && !data.error){
                let ticketResponse = await BasketService.getBasketByComplexId(state.complexId)
                commit('UPDATE_LAST_TICKET', ticketResponse.data)
                commit('CLEAR_BASKET')
                commit('UPDATE_COMMENT', null)
                resolve()
            }else{
                reject()
            }
        })
    },
    validSharedBasketInDatabase({rootState, state, commit}, {comment = ""}){
        // await dispatch('updateBasketInDatabase')
        return new Promise(async (resolve, reject) => {
            let {status, data} = await BasketService.validSharedBasket(state.complexId, comment, state.isSuspect, state.orderOrigin)
            if(status === 200 && !data.error){
                let ticketResponse = await BasketService.getBasketByComplexId(state.complexId)
                commit('UPDATE_LAST_TICKET', ticketResponse.data)
                commit('CLEAR_BASKET')
                resolve()
            }else{
                //reject()
                resolve()
            }
        })
    },
    getBasketByComplexId({rootState, state, commit}, basketComplexId){
        // await dispatch('updateBasketInDatabase')
        return new Promise(async (resolve, reject) => {

            try{
                let {data, status} = await BasketService.getBasketByComplexId(basketComplexId)
                if(status === 200 && data != ""){
                    commit('UPDATE_ORDER_TO_CHECK', data)
                    resolve(data)
                }else{
                    reject()
                }
            }catch(e){
                reject()
            }
        })
    },
    updateYomaniTicket({commit}, payload ) {
        commit('UPDATE_YOMANI_TICKET', payload)
    },
    getAllOrders({commit}, page, pageSize){
        return new Promise(async (resolve, reject) => {
            let {status, data} = await BasketService.getIECOrders(page, pageSize)
            if(status === 200 && !data.error){
                commit('UPDATE_ORDERS', data)
                resolve()
            }else{
                reject()
            }
        })
    },
    shareBasketInDatabase({rootState, state, commit}){
        // await dispatch('updateBasketInDatabase')
        return new Promise(async (resolve, reject) => {
            let {status, data} = await BasketService.shareBasket(rootState.customer.token)
            if(status === 200 && !data.error){
                let ticketResponse = await BasketService.getBasketByComplexId(state.complexId)
                commit('UPDATE_LAST_TICKET', ticketResponse.data)
                commit('CLEAR_BASKET')
                resolve()
            }else{
                reject()
            }
        })
    },
    updateLocalBasketWithDatabaseBasket({commit}, basketComplexId){
        return new Promise(async (resolve, reject) => {
            let {data, status} = await BasketService.getBasketByComplexId(basketComplexId)
            if(status === 200 && !data.error){
                commit("UPDATE_BASKET", {basketInDatabase: data})
                resolve()
            }else{
                reject()
            }
        })
    },
    updateLastTicketWithDatabaseBasket({commit}, basketComplexId){
        return new Promise(async (resolve, reject) => {
            let {data, status} = await BasketService.getBasketByComplexId(basketComplexId)
            if(status === 200 && !data.error){
                commit("UPDATE_LAST_TICKET", data)
                resolve()
            }else{
                reject()
            }
        })
    },
    updateCoupon({commit}, coupon){
        commit('UPDATE_COUPON', coupon)
    },
    updateComment({commit}, comment){
        commit('UPDATE_COMMENT', comment)
    },
    updateLastTicketComment({commit}, comment){
        commit('UPDATE_TICKET_COMMENT', comment)
    },
    setProductIsDeleted({commit}, product){
        commit('SET_PRODUCT_IS_DELETED', product)
    }
};

export const getters = {
    nbrProductsInBasket (state) {
        if(state.products.length > 0){
            return state.products.reduce((acc, product) => acc += product.qty, 0);
        }
        return 0
    },
    qtyByProductIdInBasket (state) {
        return function (id){
            let product = state.products.find(product => product.product.id === id)
            if(product){
                return product.qty
            }
            return 0
        }

    },
    basketProductsFormatted (state) {
        if(isNull(state.products)) return {productsCart: []}
        return { productsCart: state.products.map(basketProduct => {
            return {
                productId: basketProduct.product.id,
                qty: basketProduct.qty
            }
        })}
    },
    lastTicket(state) {
        return state.lastTicket
    },
    formattedBasket(state){
        return {
            id: state.id,
            basketProducts: state.products,
            totalAmount: state.totalAmount,
            complexId: state.complexId,
            orderNumber: state.orderNumber,
            subState: state.subState,
            isSuspect: state.isSuspect,
        }
    }
}
