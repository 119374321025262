const axios = require('axios');
import { config } from '@/assets/js/Utils.js';

export function getDemo(demoId) {
    let path = `/demonstration/getDemo?demo=${demoId}`
    return axios.get(config.CUSTO_WS_URL + path)
}

export function getDemoLogo(logoName) {
    let path = `/demonstration/get/logo/${logoName}`
    return config.CUSTO_WS_URL + path
}
