<template>
    <div class="search-results" v-if="isNotNull(searchResults)">
        <div v-if="searchResults.length === 0" class="noResult">No Result Founds</div>

        <div v-else>
            <div v-for="result in searchResults" :key="result.id" class="search-card">
                <v-row>
                    <v-col cols="3" md="2">
                        <img :src="productImgUrl(result.image)" class="product-image">
                    </v-col>

                    <v-col cols="9" md="10" class="d-flex flex-column">
                        <v-row class="align-center">
                            <v-col cols="12" class="product-name">{{translateName(result.name) | capitalizeFirstLetter}}</v-col>
                        </v-row>
                        <v-row class="align-center">
                            <v-col cols="6" class="product-price">{{formatPrice(result.price)}}</v-col>

                            <v-col cols="6" class="product-price d-flex justify-end">
                                <template v-if="qtyByProductIdInBasket(result.id) > 0">
                                    <v-btn fab x-small color="primary" v-if="qtyByProductIdInBasket(result.id) > 0" @click="decreaseProductInBasket({product: result, qty: 1})">
                                        <v-icon small>mdi-minus</v-icon>
                                    </v-btn>

                                    <span class="product-qty mx-2">{{qtyByProductIdInBasket(result.id)}}</span>

                                    <v-btn fab x-small color="primary" v-if="qtyByProductIdInBasket(result.id) > 0" @click="addProductInBasket({product: result, qty: 1})" class="mr-2">
                                        <v-icon small>mdi-plus</v-icon>
                                    </v-btn>
                                </template>

                                <v-btn fab x-small color="primary" v-else class="float-end mr-2" @click="addProductInBasket({product: result, qty: 1})">
                                    <v-icon small>mdi-basket-outline</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
const BasketService = require("@/services/BasketService");

export default {
    name: 'SearchResults',
    props : ['keyterm'],
    data () {
        return {
            searchResults: null,
            searchInput: "",
        }
    },
    created(){
    },
    computed:{
    },
    methods: {
        productImgUrl(image){
            return this.config.WS_URL + "/catalog/product/image/" + image;
        }
    },
    watch:{
        async keyterm(){
            let {data, status} = await BasketService.searchProducts(this.keyterm)
            if(status === 200){
                this.searchResults = data
            }
        },
    }
}
</script>
<style scoped>
.search-results {
    background: white;
    min-height: 70px;
    max-height: 70%;
    width: 100%;
    position: absolute;
    top: 69px;
    z-index: 100;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    overflow: auto;
}

.search-card {
    padding: 5px;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    border-radius: 2px;
    margin: 16px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    flex-direction: row;
    transition: 2s ease-in-out;
}

.product-name {
    font-size: 15px;
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-price {
     color: var(--color-primary);
     font-weight: bold;
     font-size: 24px;
 }

.product-image{
    width: 100%;
}

.product-qty{
    width: 23px;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .product-price {
        font-size: 18px;
    }
}
</style>
