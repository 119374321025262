export const namespaced = true;

export const state = {
    drawer: false,
    showSearchInput: false,
    currentPage:{
        title: null,
        description: null,
        showBack: false,
    },
    headerHeight: 0
};

export const mutations = {
    TOGGLE_DRAWER (state) {
        state.drawer = !state.drawer
    },
    TOGGLE_SHOWSEARCH (state) {
        state.showSearchInput = !state.showSearchInput
    },
    DRAWER_CLOSE (state) {
        state.drawer = false
    },
    CHANGE_CURRENT_PAGE (state, page){
        state.currentPage = page
    },
    CHANGE_HEADER_HEIGHT (state, headerHeight){
        state.headerHeight = headerHeight
    }
};

export const actions = {
    toggleDrawer ({ commit }) {
        commit('TOGGLE_DRAWER')
    },
    toggleShowSearch ({ commit }) {
        commit('TOGGLE_SHOWSEARCH')
    },
    drawerClose ({ commit }) {
        commit('DRAWER_CLOSE')
    },
    changeCurrentPage ({commit}, page) {
        commit('CHANGE_CURRENT_PAGE', page)
    },
    updateHeaderHeight ({commit}, headerHeight) {
        commit('CHANGE_HEADER_HEIGHT', headerHeight)
    }
};
