export const langsGlobalMixin = {
  data: function () {
      return {
          langs: {"en": "English", "de": "Deutsch", "fr": "Français", "es": "Español","it": "Italiano"}
      }
  },
  methods:{
      translateName(name){
          let lang = this.$i18n.locale
          if(this.langs[lang] && name[lang]){
              return name[lang]
          }
          return name.en
      },
      translate(word){
          let currentLang = this.$root.$i18n.locale
          let translated = word[currentLang]
          if(translated){
              return translated
          }
          for(let lang in this.langs){
              if(word[lang]){
                  translated = word[lang]
                  break;
              }
          }

          return translated
      }
  }
}
