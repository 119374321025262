<template>
	<div class="text-center ma-2">
		<v-snackbar
				:value="snackbarToShow"
				:color="snackbarColor"
				bottom
				:timeout="timeout"
		>
			<span v-html="snackbarMessage"></span>
			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="hideSnackbar" small icon>
					<v-icon small>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>

<script>
	export default {
		name: "SnackBar",
		data(){
			return {
				timeout: -1 //timeout géré côté store
			}
		},
	}

	/* Utilisation du composant :
        this.showSnackbar({
            message: "",
            type: "error", //"success", "error" or "warning"
            timeout: 5
        })
	 */
</script>

<style scoped>

</style>
