import {mapActions, mapState} from "vuex"

export const snackbarMixin = {
  computed:{
    ...mapState('snackbar', {
      snackbarToShow: 'show',
      snackbarMessage: 'message',
      snackbarColor: 'color'
    }),
  },
    methods:{
      ...mapActions('snackbar', {
        showSnackbar: 'show',
        hideSnackbar: 'hide'
      }),
    }
}
