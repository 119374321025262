const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getProductsById(id){
    let path = "/catalog/product/get/id/" + id
    return axios.get(config.WS_URL + path)
}

export function getChildByParentCategory(parentId) {
    let path = `/catalog/category/get/child/parent/id/${parentId}`
    return axios.get(config.WS_URL + path)
}

export function getProductsByCategory(categoryId) {
    let path = `/catalog/product/get/all/grouped/category/${categoryId}`
    return axios.get(config.WS_URL + path)
}
export function getProductByEan(ean){
    let path = `/catalog/product/get/ean/${ean}`
    return axios.get(config.WS_URL + path)
}
export function getCrossSellProductByProductId(productId){
    let path = `/catalog/product/get/productscrosssell/id?productId=${productId}`
    return axios.get(config.WS_URL + path)
}
