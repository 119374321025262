import {mapActions, mapState} from 'vuex'

export const pageInfoMixin = {
  created(){
    if(this.$route && this.$route.meta && this.$route.meta.page){
      this.changeCurrentPage(this.$route.meta.page)
      setTimeout(()=> {
        if(this.isNull(this.$route.meta.showHeader) || this.$route.meta.showHeader === true){
          this.updateHeaderHeight(document.getElementById("app-header").offsetHeight)
        }else(
          this.updateHeaderHeight(0)
        )       
      }, 150)
    }
  },
  computed:{
    ...mapState('header', [ 'headerHeight' ]),
  },
  methods: {
    ...mapActions('header', [
      'changeCurrentPage',
      'updateHeaderHeight'
    ]),
    deleteTopPadding(){
      setTimeout(()=> {
        this.updateHeaderHeight(0)
      }, 100)
    }
  },
}
