import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: {
          primary: "#005A8C",
          secondary: "#ffffff",
          accent: "#3c3c3c",
          mint: "#46BEAA",
          blueberry: "#41B4D2",
          customGreen: "#00B61E",
          customRed: "#B60000",
        },
      },
    },
});
