<template>
    <div class="pa-0 white rounded-pill product-bloc" :class="{'opacity': productIsDeleted, 'show-in-column': inCarousel}" v-if="show">
            <template v-if="productIsDeleted">
                <v-avatar color="white" size="76">
                    <div class="product-img" :style="{backgroundImage: `url(${productImgUrl})`}"></div>
                </v-avatar>

                <div class="product-infos px-2">
                    <span class="font-weight-bold product-name">{{ translateName(product.name) }}</span>
                    <span class="mt-2">{{ $t("productDeleted") }}</span>
                </div>
            </template>

            <template v-else-if="inCarousel">
                <v-avatar color="white" size="80" class="align-self-center">
                    <div class="product-img" :style="{backgroundImage: `url(${productImgUrl})`}"></div>
                </v-avatar>
                <div class="product-infos px-2">
                    <span class="font-weight-bold product-name">{{ translateName(product.name) }}</span>
                    <span class="font-weight-bold primary--text product-total-price">{{ formatPrice(product.price) }}</span>
                </div>


                <template v-if="!productCrossSellAdded">
                    <v-btn
                        color="primary"
                        :loading="showLoadingAddProductCrossSell"
                        small
                        class="mt-2"
                        @click="addProductCrossSellToBasket"
                        width="80%"
                    >
                            <span>{{$t('addToBasket')}}</span>
                            <v-icon small class="ms-2">mdi-cart</v-icon>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        color="primary"
                        small
                        class="mt-2"
                        width="80%"
                    >
                        <v-icon small>mdi-check-circle</v-icon>
                    </v-btn>
                </template>
            </template>

            <template v-else>
                <v-avatar color="white" size="80" class="align-self-center">
                    <div class="product-img" :style="{backgroundImage: `url(${productImgUrl})`}"></div>
                </v-avatar>
                <div class="product-infos px-2">
                    <span class="font-weight-bold product-name">{{ translateName(product.name) }}</span>
                    <span class="font-weight-bold primary--text product-total-price">{{ formatPrice(product.price * qty) }}</span>
                    <span class="primary--text product-price">{{ formatPrice(product.price) }}/{{ $t("unit") }}</span>
                    <a v-if="showMore && showMoreInfos" class="more-info" @click="showMoreHandler()">{{ $t("moreInfo") }}</a>
                </div>

                <v-spacer></v-spacer>

                <div class="mr-5 product-qty">
                    <v-btn icon elevation="4" width="25px" height="25px" @click="addProductInBasket({product: product, qty: 1})" v-if="showManageQty">
                        <v-icon color="primary" small>mdi-plus</v-icon>
                    </v-btn>
                    <span class="primary--text">{{ qty }}</span>
                    <v-btn icon elevation="4" width="25px" height="25px" @click="decreaseProductInBasket({product: product, qty: 1})" v-if="showManageQty">
                        <v-icon color="primary" small>mdi-minus</v-icon>
                    </v-btn>
                </div>
            </template>
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
        productIsDeleted: {
            type: Boolean,
            default: false
        },
        qty: Number,
        showManageQty: {
            type: Boolean,
            default: true
        },
        showMore: {
            type: Boolean,
            default: false
        },
        inCarousel: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            show: true,
            showLoadingAddProductCrossSell: false,
            productCrossSellAdded: false
        }
    },
    computed: {
        productImgUrl() {
            return this.config.WS_URL + "/catalog/product/image/" + this.product.image
        },
        showMoreInfos(){
            if(this.product.description.en?.match(/\{{(.*?)\}}/) || this.product.description.fr?.match(/\{{(.*?)\}}/)){
                return true
            }
            return false
        }
    },
    methods: {
        showMoreHandler() {
            this.$root.$emit("showAdditionalInfoModal", {product: this.product, showAddBasket: false})
        },
        addProductCrossSellToBasket(){
            this.showLoadingAddProductCrossSell = true
            this.addProductInBasket({product: this.product, qty: 1})
            setTimeout(() => {
                this.showLoadingAddProductCrossSell = false
                this.productCrossSellAdded = true
            }, 1000)
        }
    }
}
</script>

<style scoped>
.opacity {
    opacity: 0.5;
}

.product-bloc {
    position: relative;
    z-index: 10;
    border: 1px solid var(--color-primary) !important;
    padding: 4px 24px 4px 12px;
    margin: 0 auto;
    min-height: 70px;
    display: flex;
    border-radius: 50px !important;
}

.product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-img {
    width: 76px;
    height: 76px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.product-infos {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.product-total-price {
    font-size: 1.4rem;
}

.product-price {
    font-size: 0.8rem;
}

.product-qty {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.more-info {
    text-decoration: underline;
    font-style: italic;
    font-size: .85rem;
}

.product-bloc.show-in-column{
    width: 200px;
    display: grid;
    grid-template-rows: 84px auto 64px;
    grid-template-columns: 100%;
    padding: 4px !important;
    justify-items: center;
}

.product-bloc >>> .v-expansion-panel--active.v-expansion-panel-header{
    min-height: initial;
}
</style>
