import {mapActions, mapState} from "vuex"

export const loaderMixin = {
  computed:{
    ...mapState('loader', {
      loaderToShow: 'show',
      loaderMessage: 'message',
      loaderColor: 'color'
    }),
  },
    methods:{
      ...mapActions('loader', {
        showLoader: 'show',
        hideLoader: 'hide'
      }),
    }
}
