
const CustomizationService = require("@/services/CustomizationService");
export const namespaced = true;

export const state = {
    loadedDemo: JSON.parse(sessionStorage.getItem("loadedDemo"))
}

export const getters = {
    loadedDemo: state => {
        return state.loadedDemo
    },
    demoId: state => {
        if(state.loadedDemo){
            return state.loadedDemo.demoId
        }
        return null;
    },
    colorPrimary: state => {
        if(state.loadedDemo){
            return state.loadedDemo.colorPrimary
        }
        return null;
    },
    lang: state => {
        if(state.loadedDemo){
            return state.loadedDemo.lang
        }
        return null;
    },
    logo: state => {
        if(state.loadedDemo && state.loadedDemo.logo){
            return CustomizationService.getDemoLogo(state.loadedDemo.logo)
        }
        return require("@/assets/img/wl-horizontal-logo.png");
    },
    categoryId: state => {
        if(state.loadedDemo){
            return state.loadedDemo.categoryId
        }
        return null;
    },
    categoryAlone: state => {
        if(state.loadedDemo){
            return state.loadedDemo.categoryAlone
        }
        return null;
    }
}

export const mutations = {
    UPDATE_LOADED_DEMO(state, payload) {
        state.loadedDemo = payload;
        sessionStorage.setItem("loadedDemo", JSON.stringify(state.loadedDemo))
    }
}

export const actions = {
    getDemonstration({commit}, payload) {
        return new Promise(async (resolve, reject) => {
            try{
                let { data } = await CustomizationService.getDemo(payload)
                let loadedDemo = {
                    demoId: data.id,
                    colorPrimary: data.colorPrimary,
                    lang: data.lang,
                    logo: data.logo,
                    categoryId: data.categoryId,
                    categoryAlone: data.categoryAlone,
                }
                commit('UPDATE_LOADED_DEMO', loadedDemo);
                resolve()
                
            }catch(e){
                console.error(e)
                reject()
            }

        })
    }
}