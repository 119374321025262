<template>
  <div class="sidebar">

    <div class="sidebarHeader">
      <div class="sidebarHeaderContent">
        <v-btn icon @click="toggleDrawer">
          <v-icon>mdi-chevron-left </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-img max-width="200px" :lazy-src="logo" max-height="60px" :src="logo" contain></v-img>

        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title color="primary" @click="clearCache()">{{$t("clearCache")}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider class="primary"></v-divider>

    </div>

    <div class="sidebarContent">

      <div class="scrollBar">
        <v-expansion-panels flat v-model="showStorePanel">
          <v-expansion-panel  >
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <v-icon color="primary">mdi-store</v-icon>
                <span class="ms-2 primary--text">{{$t("storeInfo")}}</span>
              </div>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="text-center primary--text">
              <span class="shopName">{{config.shopName}}</span>
              <small class="grey--text text--lighten--2">({{$t("changeShopName")}})</small>
              <div class="mt-4">
                <span class="font-weight-bold d-block">{{$t("mondayToSaturday")}}</span>
                <span>08:00am - 08:00pm</span>
              </div>
              <div class="mt-4">
                <span class="font-weight-bold d-block">{{$t("sunday")}}</span>
                <span>09:00am - 08:00pm</span>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="my-1 primary"></v-divider>

        <v-expansion-panels @click.native="$router.push({name: 'Home'})" flat>
          <v-expansion-panel disabled>
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <v-icon color="primary">mdi-home</v-icon>
                <span class="ms-2 primary--text">{{$t("homeSidebarLabel")}}</span>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="my-1 primary"></v-divider>

        <v-expansion-panels @click.native="$router.push({name: 'History'})" flat>
          <v-expansion-panel disabled>
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <v-icon color="primary">mdi-history</v-icon>
                <span class="ms-2 primary--text">{{$t("ordersHistorySidebarLabel")}}</span>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="my-1 primary"></v-divider>

        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <v-icon color="primary">mdi-heart</v-icon>
                <span class="ms-2 primary--text">{{$t("FavoriteSidebarLabel")}}</span>
              </div>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="primary--text">
              <div class="d-flex align-center" v-for="(favoriteProduct, id) in favoriteProducts" :key="id" @click="handleCheckBoxClick(favoriteProduct)">
                <v-img :src="productImg(favoriteProduct)" width="40" contain></v-img>
                <span class="mx-2 one-line-title">{{translateName(favoriteProduct.name)}}</span>
                <span class="mx-1 basket-total">({{(favoriteProduct.price)}}€)</span>
                <v-checkbox class="my-0 custom-radio-btn" v-model="selectedProducts" :value="favoriteProduct.id" on-icon="mdi-checkbox-blank-circle" off-icon="mdi-checkbox-blank-circle-outline" hide-details></v-checkbox>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider class="my-1 primary"></v-divider>

        <v-expansion-panels flat>
          <v-expansion-panel disabled>
            <v-expansion-panel-header>
              <div class="d-flex align-center">
                <v-icon color="primary">mdi-help</v-icon>
                <span class="ms-2 primary--text">{{$t("needHelp")}}</span>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <div id="chooseLang">

          <v-menu  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" icon>
                <span>{{$t('currentLang')}} <small>({{$t('changeLang')}})</small> </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(lang, key) in this.langs" :key="key">
                <v-list-item-title @click="changeLang(key)">{{lang}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

        </div>
        <small class="version">{{$t('version')}} {{version}}</small>
        <small class="version text-caption">({{standalone ? "Standalone" : "Browser"}})</small>
      </div>
    </div>

    <div class="sidebarFooter">
      <div class="account-button">
        <v-icon color="white">mdi-account</v-icon>
        <span>{{$t("accountButtonLabel")}}</span>
      </div>
    </div>

  </div>
</template>
<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'Sidebar',
    data () {
      return {
        version:"",
        showStorePanel: 0,
        selectedProducts: []
      }
    },
    computed: {
      ...mapState('catalog', ['favoriteProducts', 'selectedFavoriteProduct']),
      ...mapState(['standalone']),
    },
    mounted(){
      this.version = this.config.pwaVersion;
    },
    methods: {
      ...mapActions('header', ['toggleDrawer']),

      handleCheckBoxClick(favoriteProduct) {
        let selectedProduct = this.selectedProducts.find(productId => favoriteProduct.id == productId)
        if (selectedProduct) {
          this.selectedProducts = this.selectedProducts.filter(productId => favoriteProduct.id !== productId)
        } else {
          this.selectedProducts.push(favoriteProduct.id)
        }
      },
      clearCache() {
        localStorage.clear()
        window.location.reload()
      },

      changeLang(newLang) {
        if (!Object.keys(this.langs).includes(newLang))
            newLang = "en";
        localStorage.setItem("IEC_lang", newLang)
        this.$root.$i18n.locale = newLang;
        window.location.reload()
      },

      productImg(favoriteProduct) {
        return this.config.WS_URL + `/catalog/product/image/${favoriteProduct.image}` //URL A CHANGER
      }

    }
  }
</script>
<style scoped>
  .sidebar{
    display: grid;
    grid-template-rows: 80px auto 120px;
    height: 100%;
  }
  .v-btn--icon.v-size--default .v-icon{
    font-size: 28px;
  }
  .sidebarHeaderContent{
    display: flex;
    align-items: center;
    height: 99%;
  }

  .sidebarContent{
    overflow: auto;
  }

  .shopName{
    display: block;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .product-img{
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
  }

  .nameFavoriteProduct{
    font-size:15px;
    padding: 10px  12px 17px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  }

  .basket-total {
    font-weight: bold;
    font-size: .65rem;
  }

  #chooseLang {
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }

  .version{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--color-primary);
  }

  .account-button{
    position: absolute;
    bottom: 32px;
    background-color: var(--color-primary);
    padding: 10px;
    border-radius: 50px;
    width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    color: white;
  }
  .account-button span{
    margin: 0 12px;
  }

  .one-line-title {
        max-width: 60%;
        white-space: nowrap;
        word-break: normal;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>
