export const namespaced = true;

export const state = {
	show: false,
	message: "TEST CMK",
	color: ""
};

export const mutations = {
	SHOW(state, {color, message}){
		state.message = message;
		state.color = color;
		state.show = true;
	},
	HIDE(state){
		state.message = "";
		state.color = "";
		state.show = false
	}
};

export const actions = {
	show({commit}, {color, message}){
		commit('SHOW', {color, message});
	},
	hide({commit}){
		commit('HIDE')
	}
};

export const getters = {};
