<template>
    <v-dialog v-model="showDialog" persistent class="identificationDialog">
        <v-card>
            <v-card-title class="text-h5">
                {{$t("identificationDialogTitle")}}
                <v-spacer></v-spacer>
                <v-menu offset-y bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="d-flex align-center" color="primary" text v-bind="attrs" v-on="on">
                            <span class="mr-1 font-weight-bold text-button">{{$root.$i18n.locale}}</span><v-icon x-small>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(locale, i) in locales" :key="i" @click="handleLangChange(locale)">
                            <v-list-item-title class="d-flex align-center" color="primary"><v-icon size="12" v-if="locale == $root.$i18n.locale" class="mr-2">mdi-check</v-icon> {{ $t(locale) }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-card-title>

            <v-card-text class="pb-4">
                <div class="d-flex flex-column">
                    <p class="identification-explanation" v-html="$t('identificationDialogExplanation', {demo: baseDemo})"></p>

                    <v-text-field outlined rounded hide-details v-model="firstName" :label="$t('identificationDialogFirstName')" class="custom-textfield mb-4"></v-text-field>
                    <!-- <v-text-field outlined rounded hide-details v-model="lastName" :label="$t('identificationDialogLastName')" class="custom-textfield mb-4"></v-text-field> -->
                    <!-- <v-text-field outlined rounded hide-details v-model="email" type="email" :label="$t('identificationDialogMail')" class="custom-textfield"></v-text-field> -->
                </div>
            </v-card-text>
            <v-card-actions>
                <div class="card-actions">
                    <v-divider class="mb-4"></v-divider>
                    <v-btn color="primary" rounded small @click="handleRegisterCustomer()" class="d-block ma-auto px-6">OK</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from "vuex"
    export default {
        data(vm){
            return{
                showSuccess: false,
                lastName: null,
                firstName: null,
                email: null,
                loading: false,
                showDialog: true,
                locales: vm.$i18n.availableLocales,
                baseDemo: document.title
            }
        },
        methods: {
            ...mapActions('customer', ['setCustomer', 'registerAnonym']),
            async handleRegisterCustomer(){
                try{
                    await this.setCustomer({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        email: this.email
                    })
                    this.registerAnonym()
                }catch(e){
                    console.error(e)
                }
            },
            handleLangChange(lang){
                this.$root.$i18n.locale = lang
            }
        },
    }
</script>

<style>
    .identification-explanation{
        font-size: 0.8rem;
        font-style: italic;
        text-align: justify;
        background: #e8e8e8;
        padding: 8px;
        border: solid 1px #c8c8c8;
        margin: 16px 0;
        line-height: 15px;
    }

    .custom-textfield fieldset{
        border-color: var(--color-primary) !important;
    }

    .custom-textfield .v-input__slot{
        min-height: 50px !important;
    }

    .custom-textfield label{
        top: 14px !important;
    }

    .card-actions{
        width: 100%;
    }

    .card-actions .theme--light.v-divider{
        border-color: var(--color-primary);
    }
</style>
