import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { VueMaskDirective } from 'v-mask'
import './registerServiceWorker'

import {mixin} from "./mixins/mixin"
import {langsGlobalMixin} from "./mixins/langsGlobalMixin"
import {basketMixin} from "./mixins/basketMixin"
import {customizationMixin} from "./mixins/customizationMixin"
import {snackbarMixin} from "./mixins/snackbarMixin"
import {loaderMixin} from "./mixins/loaderMixin"
import {customerMixin} from "./mixins/customerMixin"
import MQTT from "@/assets/js/Mqtt";

Vue.mixin(mixin)
Vue.mixin(langsGlobalMixin)
Vue.mixin(basketMixin)
Vue.mixin(customizationMixin)
Vue.mixin(snackbarMixin)
Vue.mixin(loaderMixin)
Vue.mixin(customerMixin)


Vue.prototype.$mqtt = MQTT

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
Vue.component('vue-phone-number-input', VuePhoneNumberInput);


import filters from '@/filters/filters';

let filterNames = Object.keys(filters);
filterNames.forEach(filterName => {
  Vue.filter(filterName, filters[filterName]);
});

Vue.directive('mask', VueMaskDirective)

require('@/assets/css/custom.css');

router.beforeEach(async (to, from, next) => {
  if(to.query.demoId){
    await store.dispatch("customization/getDemonstration", to.query.demoId)
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
