<template>
    <div class="basket-header-component">
        <v-menu v-model="basketPopover" :close-on-content-click="false" transition="v-expand-transition" origin="top" :nudge-width="200" min-width="100%" offset-y offset-x >
            <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                    <v-avatar size="32">
                        <img v-if="basketProducts && basketProducts.length > 0" :src="getProductImgUrl(basketProducts[0].product)" alt="alt">
                    </v-avatar>
                    <v-icon small>mdi-chevron-down</v-icon>
                </div>
            </template>

            <v-card class="mt-2 rounded-0">
                <v-list v-if="basketProducts && basketProducts.length > 0">
                    <v-list-item v-for="product in basketProducts" :key="product.id">
                        <v-list-item-avatar>
                            <img :src="getProductImgUrl(product.product)">
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{translateName(product.product.name)}}</v-list-item-title>
                            <v-list-item-subtitle>Qté <span class="ma-0 black--text">{{product.qty}}</span></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-2">{{formatPrice(product.product.price * product.qty)}}</v-list-item-title>
                            <v-list-item-subtitle v-if="product.qty > 1">{{formatPrice(product.product.price * product.qty)}}/{{$t("basketHeaderProductUnit")}}</v-list-item-subtitle>
                        </v-list-item-action>
                    </v-list-item>

                    <template v-if="showCrossSell && crossSellProduct != null">
                        <p class="mx-3 mt-3 mb-1 text-caption">{{$t("basketHeaderAddProductCrossSell")}}</p>

                        <v-list-item two-line dense>
                            <v-list-item-avatar>
                                <img :src="getProductImgUrl(crossSellProduct)">
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                                <v-list-item-title>{{translateName(crossSellProduct.name)}}</v-list-item-title>
                                <v-list-item-subtitle>{{formatPrice(crossSellProduct.price)}}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action class="my-1 align-center">
                                <v-btn color="primary" text small @click="addCrossSell(crossSellProduct)">+ {{$t("basketHeaderAddBtn")}}</v-btn>
                            </v-list-item-action>
                        </v-list-item>

                    </template>

                    <v-divider class="ml-15 mr-3 mt-10"></v-divider>

                    <v-list-item dense>
                        <v-list-item-avatar height="0"></v-list-item-avatar>

                        <v-list-item-content class="py-0">
                            <v-list-item-title class="align-self-end ma-0 font-weight-bold text-body-2">{{$t("basketHeaderSubtotal")}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="my-1">
                            <v-list-item-title class="align-self-end ma-0 font-weight-bold text-body-2">{{formatPrice(basketTotalAmount)}}</v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item dense v-if="validCoupon" class="mt-1 mb-3">
                        <v-list-item-avatar height="0"></v-list-item-avatar>

                        <v-list-item-content class="py-0">
                            <v-list-item-title class="mb-1 align-self-end font-italic ma-0 font-weight-medium text-body-2">{{$t("basketHeaderCoupon")}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action class="my-1">
                            <v-list-item-title class="mb-1 align-self-end font-italic ma-0 font-weight-medium text-body-2">- {{formatPrice(basketTotalAmount * 0.1)}}</v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>

                    <template v-if="showCoupon">
                        <v-divider class="ml-15 mr-3"></v-divider>
                            <v-text-field class="my-3 ml-auto mr-3 coupon-text-field" :error="couponError" hide-details="auto" :error-messages="couponErrorMessage" v-model="couponInput" :placeholder="$t('basketHeaderCouponPlaceholder')" @click:append-outer="checkCoupon" append-outer-icon="mdi-send" outlined dense>

                            <template v-slot:message="{message}">
                                <p class="ma-0 font-weight"> {{message}} </p>
                            </template>

                            </v-text-field>
                        <v-divider class="ml-15 mr-3"></v-divider>
                    </template>

                    <v-list-item>
                        <v-list-item-avatar></v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold text-body-1">{{$t("basketHeaderTotalAmount")}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-list-item-title class="mb-1 align-self-end ma-0 font-weight-bold text-body-1">{{formatPrice(totalAmount)}}</v-list-item-title>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    const CatalogService = require("@/services/CatalogService");
    export default {
        data() {
            return {
                basketPopover: false,
                couponInput: "",
                couponError: false,
                crossSellProduct: null
            }
        },
        created(){
            this.$root.$on('showBasketHeader', () => this.basketPopover = true )
            this.couponInput = this.coupon
            if(this.showCrossSell){
                this.$root.$on('refreshCrossSell', () => this.updateCrossSellProducts())
            }
        },
        methods: {
            checkCoupon() {
                this.updateCoupon(this.couponInput)
                this.couponError = !this.validCoupon
            },
            async addCrossSell(product){
                this.addProductInBasket({product: product, qty: 1})
                await this.updateBasketInDatabase(this.basketComplexId)
                this.crossSellProduct = null
                this.updateCrossSellProducts()
            },
            updateCrossSellProducts(){
                this.basketProducts.forEach(async basketProduct => {
                    let {data}  = await CatalogService.getCrossSellProductByProductId(basketProduct.product.id)
                    let products = data.filter(product => this.qtyByProductIdInBasket(product.id) == 0)
                    if(products && products.length > 0){
                        this.crossSellProduct = products[0]
                    }else{
                        this.crossSellProduct = null
                    }
                })
            }
        },
        computed: {
            showCoupon() {
                return !!this.$route.query.c
            },
            showCrossSell() {
                return !!this.$route.query.cs
            },
            totalAmount(){
                if(this.coupon == "PRW10"){
                    return this.basketTotalAmount * 0.9
                }
                return this.basketTotalAmount
            },
            validCoupon(){
                return this.coupon == "PRW10"
            },
            couponErrorMessage(){
                if(this.couponError){
                    return this.$t("basketHeaderCouponError")
                }
                return null
            }
        }
    }
</script>

<style scoped>

    .coupon-text-field{
        width: 60%;
    }
</style>
