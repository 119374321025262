<template>
    <div ref="header" class="d-flex flex-column justify-space-between full-width full-height">
        <div class="d-flex align-center">
            <v-avatar class="elevation-4" size="35" color="white" @click="handleBackHomeClick()">
                <div class="header-logo" :style="{backgroundImage: `url(${require('@/assets/img/wl-symbol.png')})`}"></div>
            </v-avatar>
            <v-spacer></v-spacer>
            <p class="ma-0 font-weight-bold">Augmented <span class="mint--text">Seller</span></p>
            <v-spacer></v-spacer>
            <BasketHeaderComponent v-if="showBasket" /> 
        </div>

        <div v-if="showTitle" class="mt-8 mb-3 d-flex flex-column">
            <div class="d-flex align-center">
                <v-btn v-if="currentPage.showBack === true" icon @click="$router.go(-1)" small>
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span v-if="currentPage.title" class="font-weight-bold text-h3" :class="{'text-h3': $route.name === 'Home', 'text-h4': $route.name !== 'Home'}">{{$t(currentPage.title)}}</span>
            </div>
            <p v-if="currentPage.description" class="mb-1 font-weight-light text-h6">{{$t(currentPage.description, {firstName: customerFirstName})}}</p>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import BasketHeaderComponent from "@/components/BasketHeaderComponent.vue"
export default {
    name: 'Header',
    props: {
        showBasket: {
            type: Boolean,
            default: false
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        disableBackHome: {
            type: Boolean,
            default: false
        },
    },
    components: {
        BasketHeaderComponent,
    },
    data () {
        return {
            searchInput: "",
            showSearchInput: false,
            basketPopover: true 
        }
    },
    computed:{
        ...mapState('header', ['currentPage','searchKeyword'])
    },
    methods: {
        ...mapActions('header', ['toggleDrawer']),
        ...mapActions('catalog', ['setSearchKeyterm']),
        closeSearch(){
            this.searchInput = "";
            this.showSearchInput = false;
        },
        handleBackHomeClick(){
            if(!this.disableBackHome){
                this.$router.push({ name: 'Home' })
            }
        }
    },
    created(){
    },
    watch:{
        searchInput(val){
            this.setSearchKeyterm(val)
        }
    }
}
</script>
<style scoped>
    .search-input>>>.v-input__slot::before,
    .search-input>>>.v-input__slot::after {
        border-style: none !important;
    }
    .header-logo{
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center top;
    }
</style>
