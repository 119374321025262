<template>
    <v-dialog
        class="additional-info-modal"
        v-model="showModal"
        scrollable
        @click:outside="$emit('hideAdditionalModal')"
    >
        <v-card style="height: 715px">
            <v-card-title style="font-size: 1.1rem">
                {{ translateName(product.name) }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="pa-2" style="height: 400px">
                <div v-if="formattedDescription || crossSellProducts">

                    <v-expansion-panels flat v-model="panels">
                        <template v-if="formattedDescription">
                            <v-expansion-panel v-for="(content, title) in formattedDescription" :key="title" class="my-1">
                                <v-expansion-panel-header color="primary" style="color: white; border-radius: 15px; min-height: 0px" hide-actions >
                                    {{ title }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="custom-expansion-panel-content max-height-expansion-panel mt-4">
                                    <div>
                                        <template v-for="(subContent, subTitle) in content">
                                            <div :key="subTitle" v-if="subTitle != '' && title == $t('rating') " class="my-2">
                                                <template v-if="subTitle == $t('averageRating')">
                                                    <p class="rating">
                                                        {{ $t("averageRating") }} :
                                                        <span class="average-rating">{{ subContent }}</span>
                                                    </p>
                                                    <image-rating
                                                        :src="require('@/assets/img/star.png')"
                                                        :item-size="30"
                                                        :show-rating="false"
                                                        :increment="0.1"
                                                        :read-only="true"
                                                        :rating="parseFloat(subContent.split('/')[0])"
                                                        class="justify-center fill-width"
                                                        v-if="subTitle == $t('averageRating')"/>
                                                </template>
                                                <span v-else><strong>{{ subTitle }} :</strong> {{ subContent }}</span>
                                            </div>

                                            <div v-else-if="subTitle != ''" :key="subTitle">
                                                <v-row class="mx-0">
                                                    <v-col cols="6" class="ps-0 pe-2 justify-start">
                                                        <span><strong>{{ subTitle }} :</strong></span>
                                                    </v-col>
                                                    <v-col cols="6" class="px-0 justify-start">
                                                        <span>{{ subContent }}</span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </template>
                                    </div>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>


                        <v-expansion-panel v-if="crossSellProducts && crossSellProducts.length > 0" class="my-1">
                            <v-expansion-panel-header color="primary" style="color: white; border-radius: 15px; min-height: 0px" hide-actions >
                                {{ $t("crossSellProducts") }}
                                <template v-slot:actions>
                                    <v-icon color="white">
                                        $expand
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="products-cross-sell mt-4">
                                    <template v-for="productCrossSell in crossSellProducts">
                                        <ProductBloc :key="productCrossSell.id" :product="productCrossSell" inCarousel></ProductBloc>
                                    </template>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    outlined
                    @click="$emit('hideAdditionalModal')"
                >
                    Close
                </v-btn>

                <v-btn
                    color="primary"
                    @click="manageProduct"
                    class="ms-2"
                    :loading="showLoaderAddBasket"
                    v-if="showAddBasket"
                >
                    <span v-if="!productAddedInBasket">{{$t('addToBasket')}}</span>
                    <span v-else>{{$t('goToBasket')}}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {ImageRating} from 'vue-rate-it';
import ProductBloc from "./ProductBloc";

const CatalogService = require("@/services/CatalogService");

export default {
    name: "AdditionalInfoModal",
    components: {
        ProductBloc,
        ImageRating
    },
    props: {
        product: {
            type: Object
        },
        showAddBasket: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            panels: 0,
            showModal: true,
            crossSellProducts: null,
            productAddedInBasket: false,
            showLoaderAddBasket: false
        }
    },
    computed: {
        formattedDescription() {
            let description = null
            let desc = this.translate(this.product.description)
            if (desc) {
                description = {}
                let splitDescription = desc.split("--end--")
                splitDescription.forEach(desc => {
                    let subDesc = {}
                    let titleMatches = desc.match(/\{{(.*?)\}}/);
                    if (titleMatches) {
                        let splitSubDesc = desc.split("}\n")[1].split("\n")
                        splitSubDesc.forEach(sub => {
                            if (sub.split(":")[1]) {
                                subDesc[sub.split(":")[0].split("?").join("")] = sub.split(":")[1].split("?").join("")
                            }
                        })
                        description[titleMatches[1]] = subDesc
                    }
                })
            }
            return description
        }
    },
    created() {
        CatalogService.getCrossSellProductByProductId(this.product.id).then(({data}) => {
            this.crossSellProducts = data
        })
    },
    methods:{
        manageProduct(){
            if(!this.productAddedInBasket){
                this.productAddedInBasket = true
                this.showLoaderAddBasket = true
                setTimeout(() => {
                    this.addProductInBasket({product: this.product, qty: 1})
                    this.showLoaderAddBasket = false
                }, 1000)
            }else{
                this.$emit('hideAdditionalModal')
                this.$router.push({name: 'Basket'})
            }
        }
    }
}
</script>

<style scoped>
.rating {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rating span {
    font-weight: bold;
    font-size: 1.5rem;
}

.average-rating {
    color: rgb(var(--color-primary));
}

.products-cross-sell {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    overflow-x: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.max-height-expansion-panel{
    max-height: 300px;
    overflow: scroll;
}

.custom-expansion-panel-content >>> .v-expansion-panel-content__wrap{
    padding: 8px !important;
}
</style>
