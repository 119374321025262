<template>
    <v-app id="app">
        <v-app-bar v-if="isNull($route.meta.showHeader) || $route.meta.showHeader" shrink-on-scroll id="app-header" class="d-flex flex-column py-2" :class="{'rounded-app-header': $route.meta.roundedHeader != false}" height="auto" color="primary" :prominent="$route.meta.shortHeader !== true" flat dark app style="z-index: 100;">
            <Header :showBasket="$route.meta.showBasket == true" :disableBackHome="$route.meta.disableBackHome == true" :showTitle="$route.meta.showTitle != false"/>
        </v-app-bar>


        <v-navigation-drawer width="80%" :value="drawer" @input="toggleVisibility($event)" fixed temporary style="z-index: 1000;">
            <Sidebar/>
        </v-navigation-drawer>

        <v-main :style="{ height: '100%', paddingTop: `${headerHeight}px` }">
            <router-view :key="$route.fullPath"></router-view>
            <AdditionalInfoModal v-if="showAdditionalModal" :product="productToShow" @hideAdditionalModal="showAdditionalModal = false; additionalModalShowAddProduct = false" :showAddBasket="additionalModalShowAddProduct"></AdditionalInfoModal>
        </v-main>

        <!-- <IdentificationDialog v-if="isNull(this.customerToken)" /> -->
        <SearchResults v-if="searchKeyterm.length > 2" :keyterm="searchKeyterm"/>
        <SnackBar/>
        <Loader/>

    </v-app>
</template>

<script>
import "@/assets/css/custom.css"
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import IdentificationDialog from '@/components/IdentificationDialog.vue';
import {mapState, mapActions} from 'vuex'
import SearchResults from "./components/SearchResults";
import SnackBar from "./components/Utils/SnackBar";
import Loader from "./components/Utils/Loader";
import {pageInfoMixin} from "@/mixins/pageInfoMixin"
import AdditionalInfoModal from "./components/AdditionalInfoModal";

var Utils = require("@/assets/js/Utils")


export default {
    components: {AdditionalInfoModal, Loader, SnackBar, SearchResults, Sidebar, Header, IdentificationDialog},
    name: 'App',
    data() {
        return {
            showAdditionalModal: false,
            productToShow: null,
            additionalModalShowAddProduct: false
        }
    },
    created() {
        this.setThemeColor(this.colorPrimary)
        if (this.isNotNull(localStorage.getItem("IEC_lang"))) {
            this.$root.$i18n.locale = localStorage.getItem("IEC_lang");
        } else {
            this.$root.$i18n.locale = 'en';
            localStorage.setItem("IEC_lang", this.$root.$i18n.locale)
        }

        if (!this.config.needIdentification && this.isNull(this.customerToken)) {
            this.registerAnonym()
        }

        Utils.saveDeviceType()

        this.$root.$on("showAdditionalInfoModal", (data) => {
            this.productToShow = data.product;
            this.showAdditionalModal = true;
            this.additionalModalShowAddProduct = data.showAddBasket
        })
    },
    mixins: [pageInfoMixin],
    computed: {
        //import state store
        ...mapState('header', ['drawer']),
        ...mapState('catalog', ['searchKeyterm']),
        ...mapState('customer', {
            customerToken: "token"
        })
    },
    mounted() {
        this.updateVh()
        window.onresize = () => {
            this.updateVh()
        };

        window.addEventListener('DOMContentLoaded', () => {
            let isStandalone = false;
            if (window.matchMedia('(display-mode: standalone)').matches) {
                isStandalone = true;
            }
            this.updateSandalone(isStandalone)
        });

    },
    methods: {
        //import action store
        ...mapActions('header', ['drawerClose']),
        ...mapActions('customer', ['registerAnonym']),
        ...mapActions(['updateSandalone']),

        //fonctions
        toggleVisibility(event) {
            if (event == false) {
                this.drawerClose()
            }
        },
        updateVh() {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    },
    watch: {
        colorPrimary() {
            this.setThemeColor(this.colorPrimary)
        },
        "$route"() {
            this.deleteTopPadding()
        }
    }

};
</script>
<style scoped>
#app {
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
}

#app >>> .v-application--wrap {
    min-height: calc(var(--vh, 1vh) * 100);
}

.rounded-app-header {
    border-bottom-right-radius: 50px !important;
}
</style>
