import {mapActions, mapGetters, mapState} from 'vuex'

export const basketMixin = {
    computed:{
        ...mapState('basket', {
            basketProducts: 'products',
            basketTotalAmount: 'totalAmount',
            basketId: 'id',
            basketComplexId: 'complexId',
            orderToCheck: 'orderToCheck',
            allOrders: 'orders',
            lastTicket: 'lastTicket',
            coupon: 'coupon',
            basketComment: 'comment'
        }),

        ...mapGetters('basket', [
            'nbrProductsInBasket',
            'qtyByProductIdInBasket',
            'formattedBasket',
        ])
    },
    methods: {
        ...mapActions('basket', [
            'addProductInBasket',
            'decreaseProductInBasket',
            'removeProductInBasket',
            'clearBasket',
            'updateBasketInDatabase',
            'validBasketInDatabase',
            'validSharedBasketInDatabase',
            'getBasketByComplexId',
            'updateYomaniTicket',
            'getAllOrders',
            'shareBasketInDatabase',
            'updateLocalBasketWithDatabaseBasket',
            'updateLastTicketWithDatabaseBasket',
            'updateCoupon',
            'updateComment',
            'updateLastTicketComment',
            'setProductIsDeleted',
        ]),
    },
}
