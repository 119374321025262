import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      page:{
        title: "page.home.title",
        description: "page.home.description",
      }
    },
    alias: [ "/standalone" ]
  },
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('@/views/Basket.vue'),
    meta: {
      page:{
        title: "page.basket.title",
        showBack: true
      },
      showBack: true
    }
  },
  {
    path: '/payment/methods',
    name: 'PaymentMethods',
    component: () => import('@/views/PaymentMethods.vue'),
    meta: {
      page:{
        title: "page.paymentMethods.title"
      }
    },
    alias: [ "/standalone/payment/methods" ]
  },
  {
    path: '/payment/paypage',
    name: 'PayPage',
    component: () => import('@/views/PayPage.vue'),
    meta: {
      page:{
        title: "page.paypage.title"
      }
    }
  },
  {
    path: '/payment/ticket',
    name: 'Ticket',
    component: () => import('@/views/Ticket.vue'),
    meta: {
      page:{
        title: "page.ticket.title"
      }
    }
  },
  {
    path: '/scan/product',
    name: 'ScanProduct',
    component: () => import('@/views/scan/ScanProduct.vue'),
    meta: {
      page:{  },
      shortHeader: true,
      showBack: true
    }
  },
  {
    path: '/payment/validation',
    name: 'PaymentValidation',
    component: () => import('@/components/PaymentValidation.vue'),
    meta:{
      page:{  },
      showHeader: false
    }
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('@/views/History.vue'),
    meta: {
      page:{
        title: "page.history.title"
      }
    }
  },
  {
    path: '/scan/terminal',
    name: 'ScanTerminal',
    component: () => import('@/views/scan/ScanTerminal.vue'),
    meta: {
      page:{
        title: "page.terminalPayment.title"
      }
    }
  },
  {
    path: '/check/order',
    name: 'ScanOrder',
    component: () => import('@/views/scan/ScanOrder.vue'),
    meta: {
      page:{
        title: "page.scanOrder.title"
      }
    }
  },
  {
    path: '/check/product',
    name: 'CheckProducts',
    component: () => import('@/views/scan/CheckProducts.vue'),
    meta: {
      page:{
        title: "page.scanOrder.title"
      }
    }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      page:{
        title: "page.orders.title"
      }
    }
  },
  {
    path: '/order/details/:orderComplexId',
    name: 'OrderDetails',
    component: () => import('@/views/OrderDetails.vue'),
    meta: {
      page:{
        title: "page.orderDetails.title"
      }
    },
    alias: [ "/standalone/order/details/:orderComplexId" ]
  },
  {
    path: '/payment/share',
    name: 'PaymentShare',
    component: () => import('@/views/PaymentShare.vue'),
    meta: {
      page:{ },
      shortHeader: true
    }
  },
  {
    path: '/payment/customer',
    name: 'CustomerPayment',
    component: () => import('@/views/CustomerPayment.vue'),
    meta: {
      page:{
        title: "page.customerPayment.title"
      },
      showBasket: true,
      roundedHeader: false,
      showTitle: false,
      disableBackHome: true
    }
  },
  {
    name: 'TicketBank',
    path: '/ticketbank',
    component: () => import('@/views/TicketBank.vue'),
    meta: {
      page:{
        title: "page.ticket.title"
      },
      shortHeader: true,
      disableBackHome: true
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
    meta: {
      page:{
        title: "page.settings.title"
      },
      showTitle: true,
    }
  },
  {
    path: '/scan/product/infos',
    name: 'ScanProductInfos',
    component: () => import('@/views/scan/ScanProductInfos.vue'),
    meta: {
      page:{
        title: "page.scanproductinfos.title"
      }
    }
  },
  {
    path: '/scan/customer',
    name: 'ScanCustomer',
    component: () => import('@/views/scan/ScanCustomer.vue'),
    meta: {
      page:{
        title: "page.scanCustomer.title"
      }
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
