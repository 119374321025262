import {config, isNotNull, isNull} from '@/assets/js/Utils.js'
const moment = require("moment")
import {mapState} from 'vuex'

export const mixin = {
  data: () => ({
    config: config
  }),
  computed: {
    ...mapState(['devMode'])
  },
  methods: {

    isEmpty(val){
      return Object.keys(val).length === 0
    },

    isNotNull(val){
      return isNotNull(val)
    },

    isNull(val){
      return isNull(val)
    },

    formatPrice(amount){
      var locale = "en";
      amount = parseFloat(amount);
      switch(locale){
          case "en":
          case "fr":
              return Math.abs(amount).toFixed(2).replace(".", ",") +"€";
      }
    },

    getCurrentDate(format = 'DD/MM/YYYY, HH:mm:ss'){
      return moment().format(format);
    },
     
    getProductImgUrl(product){
        return this.config.WS_URL + "/catalog/product/image/" + product.image
    },
    capitalize(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

  },
}
