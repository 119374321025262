/* eslint-disable no-console */

import { register } from 'register-service-worker'

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/\-/g, '+') //eslint-disable-line
      .replace(/_/g, '/'); //eslint-disable-line

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const applicationServerPublicKey = 'BOuCwC4EThTQc_O1-pPuD0HJoM9UfyZrrkJgkttwsN0Ii0uSWspvYGJiE6hhtAQ4XnvSXiElTrHJNRaJcGGZneI';
const newContentAvailableEvent = document.createEvent('Event');
newContentAvailableEvent.initEvent('newContentAvailable', true, true)

if (true) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (swRegistration) {
      const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
      swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
          .then(function(subscription) {
            localStorage.setItem('DKC_Push_Subscription', JSON.stringify(subscription))
          })
          .catch(function(err) {
            console.log('Failed to subscribe the user: ', err);
          });
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
      if ('SyncManager' in window) {
        swRegistration.sync.register('sync-demokit');
      }
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updated (swRegistration) {
      console.log('New content is available; Refresh...')
      document.dispatchEvent(newContentAvailableEvent);
      swRegistration.update()
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error(error)
    }
  })
}
