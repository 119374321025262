const axios = require('axios');
import {config, isNotNull} from '@/assets/js/Utils.js';

export function getBasketByComplexId(complexId){
    let path = "/basket/get/" + complexId
    return axios.get(config.WS_URL + path)
}

function getBasketsByState(state, token){
    let path = '/basket/get/state?token=' + token + '&state=' + state
    return axios.get(config.WS_URL + path)
}

export function getBasketsInProgress(token){
    return getBasketsByState("IN_PROGRESS", token)
}

export function manageBasketProducts(products, basketComplexId, token){
    let path = '/basket/manage/products'

    const params = new URLSearchParams()
    params.append('products', JSON.stringify(products))

    if(basketComplexId){
        params.append('basketComplexId', basketComplexId)
    }else{
        params.append('token', token)
    }

    return axios.post(config.WS_URL + path, params)
}

export function validBasket(token, comment = null, isSuspect = false, orderOrigin = null){
    let path = '/basket/valid'

    const params = new URLSearchParams()
    params.append('token', token)

    if(isNotNull(comment))
        params.append('comment', comment)

    if(isSuspect)
        params.append('isSuspect', isSuspect)

    if(isNotNull(orderOrigin))
        params.append('orderOrigin', orderOrigin)

    return axios.post(config.WS_URL + path, params)
}

export function validSharedBasket(complexId, comment = null, isSuspect = false, orderOrigin = null){
    let path = '/basket/valid/shared'

    const params = new URLSearchParams()

    if(isNotNull(complexId))
        params.append('complexId', complexId)

    if(isNotNull(comment))
        params.append('comment', comment)

    if(isSuspect)
        params.append('isSuspect', isSuspect)

    if(isNotNull(orderOrigin))
        params.append('orderOrigin', orderOrigin)

    return axios.post(config.WS_URL + path, params)
}

export function searchProducts(keyterm) {
    let path = '/search/?keyterm=' + keyterm

    return axios.get(config.WS_URL + path)
}

export function getAllFinishedOrders(token){
    return getBasketsByState("FINISH", token)
}

export function initLanePayment(token, terminalId, orderId){
    let path = '/payment/terminal/init'

    let postdata = new URLSearchParams();
    postdata.append("token", token);
    postdata.append("terminalId", terminalId);
    postdata.append("orderId", orderId);

    return axios.post(config.WS_URL + path, postdata)
}

export function checkMovePayment(token, transactionId){
    let path = '/payment/terminal/check'

    let postdata = new URLSearchParams();
    postdata.append("token", token);
    postdata.append("transactionId", transactionId);
    return axios.post(config.WS_URL + path, postdata)
}

export function getIECOrders(page, pageSize = 20){
    let path = `/basket/get/paged/IEC?page=${page}&pageSize=${pageSize}`

    return axios.get(config.WS_URL + path)
}

export function shareBasket(token){
    let path = '/basket/share'

    const params = new URLSearchParams()
    params.append('token', token)

    return axios.post(config.WS_URL + path, params)
}

export function changeBasketComment(complexId, comment){
    let path = '/basket/comment/change'

    const params = new URLSearchParams()
    params.append('complexId', complexId)
    params.append('comment', comment)

    return axios.post(config.WS_URL + path, params)
}


