const mqtt = require("mqtt");

const config = {
    host: "mqtt.labs.worldline-solutions.com",
    port: 8003,
    rejectUnauthorized: false,
    protocol: 'wss'
};

const credentials = {
    username: "rd",
    password: "@t0s@t0s"
}

const topics = {
    DEFAULT_TOPIC: "rd/chatbot/connectedKitchenBot",
    VALINA_PAYMENT: "/payment/terminal",
    YOMANI_PAYMENT: "/scanYomani/launchReservation",
    SEPTAGONE_PAYMENT: "/{terminalId}/payment/terminal",
    PAYMENT: "/payment",
    PAYMENT_WAITING: "/payment/waiting/{complexId}",
    PAYMENT_TICKET: "/payment/ticket/{complexId}",
    PAYMENT_LANE_INIT: "/payment/lane/{terminalId}/init",
    PAYMENT_LANE_DONE: "/payment/lane/{terminalId}/done",
}



String.prototype.format = function(params) {
    let string = this;
    let keys = Object.keys(params)
    for (let i in keys) {
        string = string.replace("{" + keys[i] + "}", params[keys[i]])
    }
    return string
}

let mqttReady = false;
let client;

function initMqttClient(callback = null){

    return new Promise( (resolve, reject) => {
        console.group("Init MQtt")
        console.log(client)

        try{
            if(!client || !client.connected){
                console.log("trying to connect")
                client = mqtt.connect(`${config.protocol}://${config.host}:${config.port}`, credentials);
                client.on('connect', () => {
                    console.log("connected to mqtt server")
                    mqttReady = true;
                    if(callback != null){
                        callback();
                    }
                    resolve()
                });

                client.stream.on('error', (err) => {
                    if(err){
                        console.error(err)
                        console.error("Error while connection to MQTT broker");
                        client.end();
                        reject(err)
                    }
                });
                client.on("close", () => {
                    client.reconnect();
                })
            }else{
                resolve()
            }
        }catch (e){
            reject()
        }

        console.groupEnd("Init MQtt")

    })

}



function subscribeMqtt(topic, callback){
    // console.group("Subscribe")
    // console.log("Subscribe")
    // console.log(client)
    // console.log(mqttReady)
    // console.groupEnd("Subscribe")
    if(client && client.connected && mqttReady){
        client.subscribe(topic, err => {
            if(!err){
                console.log("Subscribed to topic " + topic);
                callback();
            }else{
                console.error("Error while subscribing to topic " + err);
            }
        });
    }else{
        console.log("Not subscribed to : " + topic)
        initMqttClient(() => subscribeMqtt(topic, callback));
    }
    // console.groupEnd("Subscribe")
}

function listenMqttMessage(topicToListen, callback){
    client.on('message', (topic, message)=> {
        if(topicToListen == topic){
            console.log("Message received on topic " + topic);
            callback(message);
        }
    });
}

function unsubscribe(topic, callback){
    client.unsubscribe(topic, callback);
}

async function publishMqtt(topic, message, callback){
    if(client && client.connected && mqttReady){
        client.publish(topic, message, () => {
            console.log("Message published in topic " + topic);
            callback();
        });
    }else{
        initMqttClient(() => publishMqtt(topic, message, callback));
    }

}

function getClient(){
    return client;
}

function isMqttReady(){
    return mqttReady;
}

function endClient(){
    console.log("MQTT Client Ended")
    client.end(true);
}

const MQTT = {
    topics,
    subscribeMqtt,
    publishMqtt,
    listenMqttMessage,
    getClient,
    initMqttClient,
    endClient,
    unsubscribe,
    isMqttReady
}

export default MQTT;
