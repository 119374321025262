export const namespaced = true;

const CatalogService = require("@/services/CatalogService");

export const state = {
    favoriteProducts: JSON.parse(localStorage.getItem("IEC_favoriteProducts")) || [],
    selectedFavoriteProduct: JSON.parse(localStorage.getItem("IEC_favoriteSelectedProductsId")) || [],
    favoriteProductsId: [6169, 6170, 6172, 6173, 6176],
    searchKeyterm: ""
};

export const mutations = {
    PUSH_FAV_PRODUCT (state, productAdd) {
        state.favoriteProducts.push(productAdd)
        localStorage.setItem("IEC_favoriteProducts",JSON.stringify(state.favoriteProducts))
    },
    SET_SEARCH_KEYTERM(state, keyterm){
        console.log(keyterm)
        state.searchKeyterm = keyterm
    }
};

export const actions = {
    getFavoriteProducts ({commit}) {
        this.state.catalog.favoriteProductsId.forEach(favoriteProductId => {
            if(!this.state.catalog.favoriteProducts.find(favoriteProduct => favoriteProduct.id === favoriteProductId)){
                CatalogService.getProductsById(favoriteProductId).then( ({data}) => {
                    commit('PUSH_FAV_PRODUCT', data)
                })
            }
        })
    },
    setSearchKeyterm({commit}, keyterm){
        commit('SET_SEARCH_KEYTERM', keyterm)
    }
};
