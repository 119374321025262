import {mapActions, mapState} from 'vuex'

export const customerMixin = {
    computed:{
        ...mapState('customer', {
            customerToken: "token",
            customerComplexId: "complexId",
            customerEmail: "email",
            customerFirstName: "firstName",
            customerLastName: "lastName",
        }),
        customerFullName(){
            return `${this.customerFirstName} ${this.customerLastName}`
        }
    },
    methods: {
        ...mapActions('customer', [
            'registerAnonym'
        ]),
    },
}
