import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import * as header from '@/store/modules/header.js'
import * as catalog from '@/store/modules/catalog.js'
import * as basket from '@/store/modules/basket.js'
import * as customer from '@/store/modules/customer.js'
import * as customization from '@/store/modules/customization.js'
import * as snackbar from '@/store/modules/snackbar.js'
import * as loader from '@/store/modules/loader.js'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    key: "AugmentedSeller",
    storage: window.localStorage,
    modules: [
        "catalog",
        "basket",
        "customer"
    ]
})

export default new Vuex.Store({
    modules: {
        header,
        catalog,
        basket,
        customer,
        customization,
        snackbar,
        loader
    },
    state: {
        standalone: false,
        devMode: process.env.NODE_ENV == 'development'
    },
    mutations: {
        UPDATE_STANDALONE (state, isStandalone){
            state.standalone = isStandalone
        }
    },
    actions: {
        updateSandalone ({commit}, isStandalone) {
            commit('UPDATE_STANDALONE', isStandalone)
        }
    },
    plugins: [vuexLocal.plugin]
})
