import { mapGetters } from "vuex"
export const customizationMixin = {
    computed: {
        ...mapGetters('customization', ["loadedDemo", "colorPrimary", "lang", "logo"]),
        addColorOverlay(){
            return this.colorPrimary != "#46BEAA"
        }
    },
    methods: {
        setThemeColor(color){
            if(color){
                this.$vuetify.theme.themes.light.primary = color;
                document.documentElement.style.setProperty('--color-primary', color);
            }
        }
    }
};