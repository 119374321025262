export const namespaced = true;

export const state = {
	show: false,
	message: "",
	color: "info"
};

export const mutations = {
	SHOW(state, {type, message}){
		state.message = message;
		switch (type) {
			case 'success' :
				state.color = 'success';
				break;
			case 'error' :
				state.color = 'error';
				break;
			case 'warning' :
				state.color = 'warning';
				break;
			default:
				state.color = 'default';
				break;
		}
		state.show = true;
	},
	HIDE(state){
		state.message = "";
		state.color = "default";
		state.show = false
	}
};

export const actions = {
	show({commit, dispatch}, {type, message, timeout}){
		commit('SHOW', {type, message});
		setTimeout(() => dispatch('hide'), timeout * 1000);
	},
	hide({commit}){
		commit('HIDE')
	}
};

export const getters = {};