const axios = require('axios');
import {config, isNotNull} from '@/assets/js/Utils.js';

export function registerCustomerAnonym(firstName = null, lastName = null, email = null, token = null){
    let path = '/customer/register/anonym'

    const params = new URLSearchParams()

    if(isNotNull(firstName))
        params.append('firstName', firstName)

    if(isNotNull(lastName))
        params.append('lastName', lastName)

    if(isNotNull(email))
        params.append('email', email)

    if(isNotNull(token))
        params.append('anonymToken', token)

    if(isNotNull(config.demoName))
        params.append('demonstration', config.demoName)

    return axios.post(config.WS_URL + path, params)
}

export function getCustomerByLoyaltyCardNumber(loyaltyCardNumber){
    let path = "/customer/get/loyalty/infos?jsonInfos="

    return axios.get(config.WS_URL + path + encodeURI(JSON.stringify({loyaltyCard: loyaltyCardNumber})))
}